<template>
  <div class="mainAppContainer">
    <div v-if="showFuturePlays" class="futurePlaysContainer">
      <FuturePlays />
    </div>
    <div v-if="showResults" class="resultsContainer">
      <Results />
    </div>
    <div v-if="showNextPrizes" class="resultsContainer">
      <NextPrizes />
    </div>
  </div>
</template>

<script>
import '../css/bingoStyle.css';
import FuturePlays from '../components/FuturePlays.vue';
import Results from '../components/Results.vue';
import NextPrizes from '../components/NextPrizes.vue';
import constants from '../constants/constants';

export default {
  name: 'ComponentView',
  components: {
    FuturePlays,
    Results,
    NextPrizes,
  },
  data() {
    return {
      showFuturePlays: false,
      showResults: false,
      showNextPrizes: false,
    };
  },
  beforeMount() {
    const component = this.$store.getters.componentAvailable;
    this.showFuturePlays = (component === constants.FUTURE_PLAYS || !component);
    this.showResults = (component === constants.RESULTS);
    this.showNextPrizes = (component === constants.NEXT_PRIZES);
  },
  computed: {
  },
  methods: {
  },
};
</script>
