<template>
  <div class="resultsContainer">
    <table
      class="table"
      v-if="lastResults.length > 0"
    >
      <tbody>
        <tr
          v-for="(raffle, index) in lastResults"
          :key="index"
          class="tableRaffleRow"
        >
          <td class="tableHeaderRaffle">
            {{(`${raffle.matchName} - ${formatDate(raffle.matchDate)}`)}}
            <v-btn
              small
              rounded
              v-on:click="() => setShowDetails(raffle)"
            >
              {{ seeResultText }}
            </v-btn>
          </td>
          <td class="trLastResults">
            <tr
              v-for="(prize, indexPrize) in raffle.winners"
              :key="indexPrize"
              :class='(indexPrize % 2) ? "tableRowPurple" : "tableRowBlue"'
            >
              <td class="tdLastResults">
                <span class="text-large-width colorOrange text-center">
                  {{ String(prize.name).toUpperCase()}}
                </span>
                <br />
                <span class="text-large-width colorWhite text-center">
                  {{ formatCurrency(prize.value) }}
                </span>
              </td>
              <td class="tdLastResults">
                <span class="text-large-width colorOrange text-center">
                  {{(prize.cardIds.length > 1) ? 'Cartones Nro.' : 'Cartón Nro.' }}
                </span>
                <br />
                <span
                  v-for="(card, index) in prize.cardIds"
                  :key="index"
                  class="text-large-width colorWhite text-center">
                  {{ (prize.cardIds.length > 1 && index >= 1) ? `, ${card.id}` : card.id }}
                </span>
              </td>
            </tr>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else
      class="messageContainer text-large textLeft colorBlack"
    >
      {{ getPhrase('notPreviousRaffles') }}
    </div>
    <v-dialog
      v-if="showDetails"
      v-model="showDetails"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="tableHeaderRaffle">
          {{ raffleDetailText }}
          <v-btn
            small
            rounded
            v-on:click="hideDetails"
            class="buttonHideDetails"
          >
            {{ backText }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <table>
            <tr>
              <td class="tdDetailsResults">
                <span class="text-medium-width">
                  {{`${raffleText}: ${detailRaffle.matchId} - ${detailRaffle.matchName}`}}
                </span>
                <br />
                <span class="text-medium-width">
                  {{`${dateHourText}: ${formatDate(detailRaffle.matchDate)}`}}
                </span>
              </td>
            </tr>
            <v-divider />
            <tr>
              <td class="tdDetailsResults">
                <span class="text-large-width">{{resultText}}</span>
                <br />
                <span
                  v-for="(number, index) in detailRaffle.results"
                  :key="index"
                  :class='"numberResults text-medium-width text-center " + `${getColor(index)}`'>
                  {{ (number >= 10) ? number : `0${number}` }}
                </span>
              </td>
            </tr>
            <v-divider />
            <tr>
              <td class="tdDetailsResults">
                <span class="text-large-width">{{this.winnersText}}</span>
              </td>
            <tr>
              <td class="">
                <tr
                  v-for="(prize, indexPrize) in detailRaffle.winners"
                  :key="indexPrize"
                  :class='(indexPrize % 2) ? "tableRowPurple" : "tableRowBlue"'
                >
                  <td class="tdDetailsResults">
                    <div>
                      <span class="text-medium-width colorOrange text-center">
                        {{`${String(prize.name).toUpperCase()}: ${formatCurrency(prize.value)}`}}
                      </span>
                    </div>
                    <div
                      v-for="(card, index) in prize.cardIds"
                      :key="index"
                      class="tdWinnerCard"
                    >
                      <div class="displayTable">
                        <span class="text-medium-width colorWhite text-center">
                          {{`${cardIdText}: ${card.id}`}}
                          <br />
                          {{`${agencyText}: ${card.sellerId}`}}
                          <br />
                          {{`${cityText}: ${card.locationId}`}}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-plusplus */
import APP_UTILS from '../config/app_utils';
import constants from '../constants/constants';

export default {
  name: 'Results',
  data() {
    return {
      showDetails: false,
      detailRaffle: {},
      colorResults: [],
      prizeToShow: [],
      matchDateFirtsRaffle: null,
      seeResultText: '',
      raffleDetailText: '',
      backText: '',
      raffleText: '',
      dateHourText: '',
      resultText: '',
      winnersText: '',
      cardIdText: '',
      agencyText: '',
      cityText: '',
    };
  },
  computed: {
    lastResults() {
      const data = this.$store.getters.lastResults;
      const lastResults = this.lastResultsParse(data);
      return lastResults;
    },
  },
  beforeMount() {
    this.seeResultText = this.getPhrase('seeResult');
    this.raffleDetailText = this.getPhrase('raffleDetail');
    this.backText = this.getPhrase('back');
    this.raffleText = this.getPhrase('raffle');
    this.dateHourText = this.getPhrase('dateHour');
    this.resultText = this.getPhrase('result');
    this.winnersText = this.getPhrase('winners');
    this.cardIdText = this.getPhrase('cardId');
    this.agencyText = this.getPhrase('agency');
    this.cityText = this.getPhrase('city');
  },
  methods: {
    formatCurrency(value) {
      return APP_UTILS.formatCurrency(value);
    },
    formatDate(time) {
      const timestampValid = ((new Date(time)).getTime() > 0);
      if (!timestampValid) {
        return '';
      }

      const date = new Date(time);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      day = (day >= 10) ? day : `0${day}`;
      month = (month >= 10) ? month : `0${month}`;
      year = (year >= 10) ? year : `0${year}`;

      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      h = (h >= 10) ? h : `0${h}`;
      m = (m >= 10) ? m : `0${m}`;
      s = (s >= 10) ? s : `0${s}`;

      const dateDraw = `${day}/${month}/${year} - ${h}:${m}:${s}`;

      return dateDraw;
    },
    lastResultsParse(lastResults) {
      const prizeToShow = [];
      if (lastResults.length === 0) { return prizeToShow; }

      if (lastResults[0].matchDate === this.matchDateFirtsRaffle) {
        return this.prizeToShow;
      }

      for (let index = 0; index < lastResults.length; index++) {
        const raffle = lastResults[index];
        const winners = [];
        for (let apIndex = 0; apIndex < raffle.awardedPrizes.length; apIndex++) {
          const prize = raffle.awardedPrizes[apIndex];
          const seller = (prize.sellerId === '') ? '-' : prize.sellerId;
          const location = (prize.location === '') ? '-' : prize.location;
          const prizeAvailable = winners.filter((p) => p.prizeId === prize.prizeId);
          if (!prizeAvailable.length) {
            winners.push({
              name: prize.name,
              prizeId: prize.prizeId,
              value: prize.value,
              cardIds: [{
                id: prize.cardId,
                locationId: location,
                sellerId: seller,
              }],
            });
          } else {
            const { cardIds } = prizeAvailable[0];
            cardIds.push({
              id: prize.cardId,
              locationId: location,
              sellerId: seller,
            });
          }
        }
        raffle.winners = [...winners];
        prizeToShow.push(raffle);
      }

      if (prizeToShow.length) {
        this.matchDateFirtsRaffle = prizeToShow[0].matchDate;
        this.prizeToShow = prizeToShow;
      }

      return prizeToShow;
    },
    setColors(detailData) {
      const { results, awardedPrizes } = detailData;
      const styleLine = 'resultPrize_1';
      const styleBingo = 'resultPrize_0';
      this.colorResults = [];

      for (let i = 0; i < results.length; i++) {
        this.colorResults.push(styleBingo);
        for (let indexAP = 0; indexAP < awardedPrizes.length; indexAP++) {
          const prize = awardedPrizes[indexAP];

          if (prize.prizeId === constants.PRIZE_ID_LINE && i < prize.ballPosition) {
            this.colorResults[i] = styleLine;
          }
        }
      }
    },
    getColor(index) {
      return this.colorResults[index];
    },
    getPhrase(phraseId, upperCase) {
      if (!phraseId) { return ''; }

      return APP_UTILS.getPhrase(phraseId, upperCase);
    },
    setShowDetails(dataRaffle) {
      this.detailRaffle = dataRaffle;
      this.setColors(this.detailRaffle);
      this.showDetails = true;
    },
    hideDetails() {
      this.showDetails = false;
      this.detailRaffle = {};
    },
  },
};
</script>
