const languageES = {
  agency: 'Agencia',
  back: 'volver',
  Bingo: 'Bingo',
  cardId: 'Cartón Nro.',
  cardIds: 'Cartones Nro.',
  cardsPurchased: 'Tenés cartones comprados',
  myCards: 'Tenés %1 cartones para este sorteo',
  playFor: 'JUGÁ POR!',
  city: 'Ciudad',
  date: 'Fecha',
  dateHour: 'Fecha y Hora',
  hour: 'Hora',
  line: 'Linea',
  liveRaffle: 'Sorteo en curso',
  preparingRaffle: 'Sorteo por iniciar',
  raffle: 'Sorteo',
  raffleDetail: 'Detalle del Sorteo',
  result: 'Resultado',
  value: 'Valor',
  seeResult: 'Ver Resultado',
  winners: 'Ganadores',
  notPreviousRaffles: 'No existen partidas anteriores.',
  notFuturePlays: 'El juego se encuentra temporalmente de vacaciones',
  notMoreBets: 'Venta de cartones finalizada',
};

export default languageES;
