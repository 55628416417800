const dataTest = {
  nextPrizes: [
    {
      name: 'Linea',
      prizeId: 10,
      prizeType: 'Line',
      value: 450.0,
    },
    {
      name: 'Super Linea',
      prizeId: 15,
      prizeType: 'Line',
      value: 953.75,
    },
    {
      name: 'Bingo',
      prizeId: 20,
      prizeType: 'Bingo',
      value: 750.0,
    },
    {
      name: 'Super Bingo',
      prizeId: 21,
      prizeType: 'Bingo',
      value: 10825.0,
    },
    {
      name: 'Bingo Oro',
      prizeId: 22,
      prizeType: 'Bingo',
      value: 7802.5,
    },
    {
      name: 'Bingo Plata',
      prizeId: 23,
      prizeType: 'Bingo',
      value: 5787.5,
    },
    {
      name: 'Bingo Bronce',
      prizeId: 24,
      prizeType: 'Bingo',
      value: 3772.5,
    }],
  noPrizes: [],
};

export default dataTest;
