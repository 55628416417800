<template>
  <div class="nextPrizesContainer">
    <div
      class="prizesContainer"
      v-if="nextPrizes && nextPrizes.length > 0"
    >
      <div
        class="backPrize"
        v-for="(prize, index) in showPrize"
        v-bind:key="index"
      >
        <div :class="'backPrize_' + index">
          <div class="prizesMessageText">
            <div class="text-title-bigger colorWhite py-4">
              {{prize.name}}
            </div>
            <div class="lineBorderPrize"></div>
            <div class="text-xlarge colorOrange py-4">
              {{formatCurrency(prize.value)}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="prizesMessage"
      v-else
    >
      <div class="prizesMessageText text-xlarge colorWhite py-4 px-2">
        ¡¡¡MÁS DE $3.500.000 EN PREMIOS!!!
      </div>
    </div>
  </div>
</template>
<script>
import APP_UTILS from '../config/app_utils';
/* eslint-disable no-plusplus */

export default {
  name: 'NextPrizes',
  data() {
    return {
      indexPrizes: 0,
      showPrize: [],
      nextPrizesData: [],
    };
  },
  beforeMount() {
  },
  computed: {
    nextPrizes() {
      const prizes = this.$store.getters.nextPrizes;
      this.changePrizeData(prizes);
      return prizes;
    },
  },
  methods: {
    changePrizeData(nextPrizes) {
      if (nextPrizes && nextPrizes.length) {
        let updateData = true;
        for (let i = 0; i < nextPrizes.length; i++) {
          const data = nextPrizes[i];
          for (let index = 0; index < this.nextPrizesData.length; index++) {
            const prize = this.nextPrizesData[index];
            data.name = String(data.name).toUpperCase();
            if (prize.name === data.name && prize.value === data.value) {
              updateData = false;
              break;
            }
          }
        }

        if (updateData && nextPrizes.length > this.nextPrizesData.length) {
          updateData = true;
        }

        if (updateData) {
          this.nextPrizesData = nextPrizes;
          clearTimeout(this.currentPrizeId);
          this.currentPrizeId = null;
          this.updatePrize();
        }
      }
    },
    updatePrize() {
      if (this.nextPrizesData && this.nextPrizesData.length) {
        const nextPrizes = [...this.nextPrizesData];
        const maxPrize = 2;
        let startIndex = this.indexPrizes;
        let endIndex = startIndex + maxPrize;
        let showPrize = nextPrizes.slice(startIndex, endIndex);

        if (showPrize.length < maxPrize) {
          startIndex = 0;
          endIndex = maxPrize - showPrize.length;
          const diffItems = nextPrizes.slice(startIndex, endIndex);
          showPrize = [...showPrize, ...diffItems];
        }

        this.showPrize = showPrize;
        this.indexPrizes = endIndex;
      }

      const timeout = 3000;
      const that = this;
      this.currentPrizeId = setTimeout(() => {
        clearTimeout(that.currentPrizeId);
        that.updatePrize();
      }, timeout);
    },
    formatCurrency(value) {
      return APP_UTILS.formatCurrency(value);
    },
  },
};
</script>
