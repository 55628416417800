import languageES from '../languages/language_ES';

const APP_UTILS = {
  formatCurrencyNumber: (number, decimalPlaces, decimalSeparator, thousandSeparator) => {
    const dSeparator = decimalSeparator || '.';
    const thousandSep = thousandSeparator || ',';
    let dPlaces = Math.abs(decimalPlaces);
    // eslint-disable-next-line no-restricted-globals
    dPlaces = isNaN(dPlaces) ? 2 : decimalPlaces;

    // const s = number < 0 ? '-' : '';
    const integer = String(number).split(thousandSeparator);
    const num = (integer.length) ? (integer[0]) : (Math.abs(+number || 0).toFixed(dPlaces));
    const i = `${Number(num)}`;
    let j = i.length;
    // eslint-disable-next-line no-cond-assign
    const dig = (i < 0) ? 4 : 3;
    j = (i.length) > dig ? j % 3 : 0;

    return (
      (j ? i.substr(0, j) + thousandSep : '')
      + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousandSep}`)
      + (dPlaces ? dSeparator + Math.abs(number - i).toFixed(dPlaces).slice(2) : '')
    );
  },

  formatCurrency: (number, integer) => {
    const num = number || 0;
    const decimal = (integer) ? 0 : 2;
    const formattedNumber = APP_UTILS.formatCurrencyNumber(num, decimal, ',', '.');
    return `$${formattedNumber}`;
  },

  getPhrase: (phraseId, upperCase) => {
    const isUpperCase = upperCase || false;
    let phrase = '';
    // eslint-disable-next-line no-prototype-builtins
    if (languageES.hasOwnProperty(phraseId)) {
      phrase = languageES[phraseId];

      if (isUpperCase) {
        phrase = phrase.toLocaleUpperCase();
      }
    }

    return phrase;
  },
};

export default APP_UTILS;
