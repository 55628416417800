const constants = {
  FUTURE_PLAYS: 'FUTURE_PLAYS',
  RESULTS: 'RESULTS',
  NEXT_PRIZES: 'NEXT_PRIZES',

  SET_DATA_FROM_SERVER: 'setDataFromServer',
  SET_UPCOMING_DRAW: 'setUpcomingDraw',
  SET_INITIALIZED_APP: 'setInitializedApp',
  SET_LAST_RESULTS: 'setLastResults',
  SET_SHOW_GAME: 'setShowGame',
  SET_NEXT_PRIZES: 'setNextPrizes',

  INITIALIZE_APP: 'initializeApp',
  UPDATE_DATA_UPCOMINGDRAW: 'updateDataUpcomingDraw',
  UPDATE_DATA_LAST_RESULTS: 'updateDataLastResults',
  UPDATE_DATA_NEXT_PRIZES: 'updateDataNextPrizes',
  SHOW_ERROR_MESSAGE: 'showErrorMessage',

  PRIZE_ID_LINE: 10,
};

export default constants;
