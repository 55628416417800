<template>
  <v-app>
    <v-main class="appWidget">
      <TvView />
    </v-main>
  </v-app>
</template>

<script>
/* eslint-disable func-names */
/* eslint-disable no-console */
import './css/bingoStyle.css';
import TvView from './view/tvView.vue';
import constants from './constants/constants';

export default {
  name: 'App',
  components: {
    TvView,
  },

  data: () => ({
  }),
  mounted() {
    this.$store.dispatch(constants.INITIALIZE_APP);
  },
  methods: {
  },
};
</script>
