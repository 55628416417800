const CONFIG_APP = {
  BASE_API: process.env.VUE_APP_BASE_URL_API,
  GAME_ID: process.env.VUE_APP_GAME_ID,
  SITE_ID: process.env.VUE_APP_SITE_ID,
  OPERATOR_ID: process.env.VUE_APP_OPERATOR_ID,
  ENDPOINT_TABLE_STATUS: 'api/table_status/',
  ENDPOINT_LAST_MATCHES_RESULTS: 'api/last_matches_results',
  ENDPOINT_GET_NEXT_MATCHES: 'api/get_next_matches',
  ENDPOINT_GET_NEXT_MATCHES_CARDS: 'api/get_next_matches_cards',
  ENDPOINT_RUNNING_TABLES: 'api/running_tables',
};

export default CONFIG_APP;
