/* eslint-disable quotes */
import Vue from 'vue';
import Vuex from 'vuex';
import CommManager from '../api/commManager';
import constants from '../constants/constants';

Vue.use(Vuex);

const location = window && window.location;
const paramsUrl = location && location.search;
const searchParams = new URLSearchParams(paramsUrl);
const isIOS = ((navigator.userAgent.match(/(iPhone|iPad|iPod|Mac)/i)) != null);

export default new Vuex.Store({
  state: {
    upcomingDraw: [],
    lastResults: [],
    nextPrizes: [],
    currentRaffleData: {
      matchId: 0,
      matchName: '',
      cardValue: 0.00,
      accumulatedPrize: 0.00,
      accumulatedPrizeBalls: 0,
      nextPrizes: [],
      nextDateDraw: {},
      timestamp: '',
    },
    winners: [],
    searchParams,
    location,
    isIOS,
    initializedApp: false,
    showGame: false,
    showingPrize: false,
    showAnticipation: false,
    showIdle: true,
    gameInProcess: false,
    message: {
      type: '',
      content: '',
    },
  },
  mutations: {
    setDataFromServer(state, data) {
      if (!data) return;

      const currentRaffleData = { ...state.currentRaffleData, ...data.currentRaffleData };

      state.winners = data.winners || [];
      state.statusPlay = data.statusPlay;
      state.currentRaffleData = currentRaffleData;
      state.showGame = true;
    },
    setUpcomingDraw(state, data) {
      state.upcomingDraw = data;
      state.nextDateDraw = data && data.length && data[0];
      state.showGame = true;
    },
    setLastResults(state, param) {
      state.lastResults = param;
      state.showGame = true;
    },
    setNextPrizes(state, data) {
      state.nextPrizes = data;
    },
    setInitializedApp(state, param) {
      state.initializedApp = param;
    },
    setShowGame(state, param) {
      state.showGame = param;
    },
  },
  getters: {
    upcomingDraw(state) {
      return state.upcomingDraw.slice(0, 10);
    },
    lastResults(state) {
      return state.lastResults.slice(0, 10);
    },
    nextPrizes(state) {
      return state.nextPrizes;
    },
    currentRaffleData(state) {
      return state.currentRaffleData;
    },
    awardedPrizes(state) {
      return state.awardedPrizes;
    },
    winners(state) {
      return state.winners;
    },
    startGame(state) {
      return state.startGame;
    },
    showGame(state) {
      return state.showGame;
    },
    statusPlay(state) {
      return state.statusPlay;
    },
    componentAvailable(state) {
      return state.searchParams.get("component") || false;
    },
    getUserId(state) {
      return state.searchParams.get("userID") || false;
    },
    getGameId(state) {
      return state.searchParams.get("gameId") || false;
    },
    getSiteId(state) {
      return state.searchParams.get("siteId") || false;
    },
  },
  actions: {
    initializeApp(context) {
      const commManager = new CommManager();
      commManager.initialize(this);
      context.commit(constants.SET_INITIALIZED_APP, true);
    },
    updateDataUpcomingDraw(context, data) {
      context.commit(constants.SET_UPCOMING_DRAW, data);
    },
    updateDataLastResults(context, data) {
      context.commit(constants.SET_LAST_RESULTS, data);
    },
    updateDataNextPrizes(context, data) {
      context.commit(constants.SET_NEXT_PRIZES, data);
      context.commit(constants.SET_SHOW_GAME, true);
    },
    showErrorMessage(context, param) {
      context.commit(constants.SET_SHOW_GAME, param);
    },
  },
  modules: {
  },
});
