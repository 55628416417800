<template>
  <div
    class="futurePlaysContainer"
  >
    <div
      class="tableContainer"
      v-if="upcomingDraw.length > 0"
    >
      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="(play, index) in upcomingDraw"
          :key="index"
          class="animation_movementY"
          style="marginBottom: 1%"
        >
          <v-expansion-panel-header>
            <div>
              <v-row no-gutters :class="`tableHeader ${play.styleHeader}`">
                  <v-col cols="1">
                    <v-fade-transition leave-absolute>
                      <v-img
                        contain
                        alt="calendar Icon"
                        max-width="4.5vw"
                        src="../assets/image/calendar.png"
                        transition="fade-transition"
                      />
                    </v-fade-transition>
                  </v-col>
                  <v-col cols="10">
                    <span class="titleText text-large-width colorWhite">
                      {{`${getPhrase('date')}: ${play.date}`}}
                    </span>
                  </v-col>
                  <v-col cols="1">
                    <v-fade-transition leave-absolute>
                      <v-img
                        contain
                        alt="expand Icon"
                        src="../assets/image/expand.png"
                        max-width="3.5vw"
                      />
                    </v-fade-transition>
                  </v-col>
              </v-row>
              <v-row
                :class="play.style"
                align="center"
              >
                <v-col
                  cols="5"
                  class="subTitle text-large-width"
                >
                  <span>{{ play.matchName }}</span>
                  <span class="text-subtitle-small">{{ play.subTitle }}</span>
                </v-col>
                <v-col
                  cols="7"
                  align-self="center"
                  class="backImageCoins text-large-width textRight colorBlack000"
                >
                  <span class="">{{ playFor }}</span>
                  <br />
                  <span class="">{{ play.totalPrize }}</span>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="tableRow colorGrey">
            <v-row no-gutters class="tableHeader colorGrey">
              <v-col cols="1">
                <v-fade-transition leave-absolute>
                  <v-img
                    contain
                    alt="clock Icon"
                    src="../assets/image/clock.png"
                    max-width="4.5vw"
                  />
                </v-fade-transition>
              </v-col>
              <v-col cols="5">
                <span class="titleText">{{ play.startTime }}</span>
              </v-col>
              <v-col cols="1">
                <v-fade-transition leave-absolute>
                  <v-img
                    contain
                    alt="cash Icon"
                    src="../assets/image/coins.png"
                    max-width="4.5vw"
                  />
                </v-fade-transition>
              </v-col>
              <v-col cols="5">
                <span class="titleText">{{ play.cardValue }}</span>
              </v-col>
            </v-row>
            <v-expansion-panels
              flat
              accordion
              tile
              v-if="play.showCardPurchase"
            >
              <v-expansion-panel :key="index">
                <v-expansion-panel-header color="#D5E9FF">
                  <v-row>
                    <v-col cols="10">
                      <span class="titleText">
                        {{play.cardsPurchasedText}}
                      </span>
                    </v-col>
                    <v-col cols="2">
                      <v-fade-transition leave-absolute>
                        <v-img
                          contain
                          alt="expand Icon"
                          src="../assets/image/expandBlack.png"
                          max-width="3vw"
                        />
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="tableRow colorGrey">
                  <div class="cardsIdContainer">
                    <div
                      v-for="(card, index) in play.cardsById"
                      :key="index"
                      class="cardItem text-center"
                    >
                      <div class="cardItemIdText">
                        <span class="text-subtitle-small">
                          {{`Nro. Cartón: ${index}`}}
                        </span>
                      </div>
                      <div class="cardsItemsContainer text-large-width">
                        <span
                          v-for="(num, pos) in card"
                          :key="pos"
                          :class="(num > 0) ? 'cardItemWithNum' : 'cardItemWithoutNum'"
                        >
                          <span v-if="num > 0">
                            {{ (num >= 10) ? num : `0${num}` }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div
      v-else
      class="messageContainer text-subtitle-large textLeft colorBlack"
    >
      {{ getPhrase('notFuturePlays') }}
    </div>
  </div>
</template>

<script>
import APP_UTILS from '../config/app_utils';
/* eslint-disable no-console */
/* eslint-disable no-plusplus */

export default {
  name: 'FuturePlays',
  data() {
    return {
      headers: [
        { text: this.getPhrase('raffle'), align: 'start' },
        { text: this.getPhrase('hour'), align: 'center' },
        { text: this.getPhrase('value'), align: 'center' },
      ],
      futurePlaysData: [],
      cardIdText: '',
      playFor: '',
    };
  },
  computed: {
    upcomingDraw() {
      const upcoming = this.$store.getters.upcomingDraw;
      const futurePlays = this.futurePlaysParse(upcoming);
      return futurePlays;
    },
  },
  beforeMount() {
    this.cardIdText = this.getPhrase('cardId');
    this.playFor = this.getPhrase('playFor');
  },
  methods: {
    futurePlaysParse(playsData) {
      const futurePlays = [];
      const now = Date.now();

      for (let index = 0; index < playsData.length; index++) {
        const play = playsData[index];
        const timestampValid = ((new Date(play.startTime)).getTime() > 0);
        let timeDraw = '00:00:00';
        let dateDraw = '00/00/0000';
        if (timestampValid) {
          const date = new Date(play.startTime);
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          let h = date.getHours();
          let m = date.getMinutes();
          let s = date.getSeconds();
          day = (day >= 10) ? day : `0${day}`;
          month = (month >= 10) ? month : `0${month}`;
          year = (year >= 10) ? year : `0${year}`;
          h = (h >= 10) ? h : `0${h}`;
          m = (m >= 10) ? m : `0${m}`;
          s = (s >= 10) ? s : `0${s}`;

          timeDraw = `${h}:${m}:${s}`;
          dateDraw = `${day}/${month}/${year}`;
        }

        const cardsPurchased = play.cardsId && play.cardsId.length;
        const cardsId = play.cardsId || [];
        const cardsById = play.cardsById || {};
        const showCardPurchase = (play.cardsId && play.cardsId.length > 0);
        let style = 'colorGrey';
        let styleHeader = 'colorBlueLight';
        let subTitle = '';
        let cardsPurchasedText = (cardsPurchased > 0) ? this.getPhrase('myCards') : '';
        cardsPurchasedText = cardsPurchasedText.replace('%1', cardsPurchased);

        if (play.closePurchaseTime <= now) {
          style = 'colorGreyDisable';
          styleHeader = 'colorGreyDark';
          subTitle = this.getPhrase('notMoreBets');
        }

        if (play.cardsId && play.cardsId.length > 0) {
          style = 'colorCardsPurchased';
          subTitle = this.getPhrase('cardsPurchased');
        }

        if (play.currentState) {
          style = 'colorRedLiveDraw';
          styleHeader = (play.currentState === 'AcceptingBets') ? 'colorBlueLight' : 'colorGreyDark';
          subTitle = (play.currentState === 'Raffling') ? 'liveRaffle' : 'preparingRaffle';
          subTitle = this.getPhrase(subTitle);
        }

        let totalPrize = 0;
        Object.keys(play.prizesById).forEach((prizeIndex) => {
          totalPrize += play.prizesById[prizeIndex].value;
        });

        const newPlay = {
          cardsId,
          cardsById,
          cardsPurchasedText,
          cardValue: APP_UTILS.formatCurrency(play.cardValue),
          date: dateDraw,
          matchName: play.matchName,
          showCardPurchase,
          startTime: timeDraw,
          style,
          styleHeader,
          subTitle,
          totalPrize: APP_UTILS.formatCurrency(totalPrize),
        };

        futurePlays.push(newPlay);
      }

      this.futurePlaysData = futurePlays;
      return futurePlays;
    },
    getPhrase(phraseId, upperCase) {
      if (!phraseId) { return ''; }
      return APP_UTILS.getPhrase(phraseId, upperCase);
    },
  },
};
</script>
