<template>
  <div class="appWidget">
    <IdleApp
      v-if="showIdle && !showGame"
    />
    <ComponentView v-if="showGame" />
  </div>
</template>

<script>
/* eslint-disable no-console */
import IdleApp from '../components/idle.vue';
import ComponentView from './ComponentView.vue';

export default {
  name: 'TvView',
  components: {
    IdleApp,
    ComponentView,
  },
  data() {
    return {
    };
  },
  computed: {
    showIdle() {
      return (!this.$store.getters.showDraw && !this.$store.getters.startRaffle);
    },
    showGame() {
      return this.$store.getters.showGame;
    },
  },
  methods: {
    //
  },
};
</script>
