/* eslint-disable no-plusplus */
import constants from '../constants/constants';
import APP_CONFIG from '../config/app_config';
import dataTest from './DataTest';

/* eslint-disable max-len */
/* eslint-disable no-console */
export default class CommManager {
  constructor() {
    this.currentDraw = null;
    this.initialized = false;
    this.lastResultsData = null;
    this.liveRaffleData = null;
    this.runningTables = [];
    this.store = null;
    this.userID = null;
    this.gameId = null;
    this.siteId = null;
    this.upcomingRafflesData = [];

    // Saves old draws
    this.oldDrawsRaffled = [];

    // Set listeners and timers
    this.listenerLastResults = null;
    this.listenerLiveRaffleData = null;
    this.listenerUpcomingRafflesData = null;
    this.timerLastResults = 5000;
    this.timerLiveRaffle = 2000;
    this.timerUpcomingRaffles = 5000;

    this.requestLastResultsError = 0;
    this.requestUpcomingRafflesError = 0;
  }

  initialize(store) {
    this.initialized = true;
    this.store = store;

    // Start Requests
    const {
      componentAvailable,
      getUserId,
      getGameId,
      getSiteId,
    } = this.store.getters;
    this.userID = getUserId || null;
    this.gameId = getGameId || null;
    this.siteId = getSiteId || null;
    if (componentAvailable && componentAvailable === constants.RESULTS) {
      this.dispatchRequestLastResults(true);
    } else if (componentAvailable && componentAvailable === constants.NEXT_PRIZES) {
      this.store.dispatch(constants.UPDATE_DATA_NEXT_PRIZES, dataTest.noPrizes);
    } else {
      this.dispatchRequestUpcomingRaffles(true);
    }
  }

  parseUpcomingDraws(responseData) {
    // Definitions
    const parsedData = [];
    const draws = responseData || [];
    const currentDate = Date.now();

    // Parse Data and remove the old draws
    for (let i = 0; i < draws.length; i++) {
      const draw = draws[i];
      const dateDraw = new Date(draw.startTime).getTime();
      // Save only the future draws
      if (dateDraw >= currentDate || draw.currentState) {
        parsedData.push(draw);
      }
    }

    // Sort draws
    if (parsedData.length) {
      const sortedData = parsedData.sort((a, b) => {
        const aDate = new Date(a.startTime).getTime();
        const bDate = new Date(b.startTime).getTime();
        return aDate - bDate;
      });

      this.upcomingRafflesData = sortedData;
      // Dispatch data to Vuex Store
      this.store.dispatch(constants.UPDATE_DATA_UPCOMINGDRAW, sortedData);
      this.requestUpcomingRafflesError = 0;
    } else {
      this.requestUpcomingRafflesError += 1;
      if (this.requestUpcomingRafflesError === 3) {
        this.store.dispatch(constants.SHOW_ERROR_MESSAGE, true);
      }
    }
  }

  parseLastResults() {
    const draws = this.lastResultsData || [];

    // Remove raffle without results
    const lastDraws = draws.filter((draw) => (draw.results && draw.results.length));
    lastDraws.map((draw) => {
      draw.awardedPrizes.map((prize) => {
        const parsePrize = prize;
        const cardValue = parsePrize.cardValues.filter((value) => (value > 0));
        parsePrize.cardValues = [...cardValue];
        return parsePrize;
      });

      return true;
    });

    // New data for last Results
    if (lastDraws && lastDraws.length) {
      const sortedData = lastDraws.sort((a, b) => {
        const aDate = new Date(a.matchDate).getTime();
        const bDate = new Date(b.matchDate).getTime();
        return bDate - aDate;
      });

      this.store.dispatch(constants.UPDATE_DATA_LAST_RESULTS, sortedData);
      this.requestLastResultsError = 0;
    } else {
      this.requestLastResultsError += 1;
      if (this.requestLastResultsError === 3) {
        this.store.dispatch(constants.SHOW_ERROR_MESSAGE, true);
      }
    }
  }

  dispatchRequestLastResults(retryMode) {
    this.listenerLastResults = setTimeout(() => {
      clearTimeout(this.listenerLastResults);
      this.getLastResults()
        .then((response) => {
          if (response) {
            this.lastResultsData = response;
            this.parseLastResults();
          }
        });
    }, (!retryMode) ? this.timerLastResults : this.timerLiveRaffle);
  }

  dispatchRequestUpcomingRaffles(retryMode) {
    this.listenerUpcomingRafflesData = setTimeout(() => {
      clearTimeout(this.listenerUpcomingRafflesData);
      this.getUpcomingRaffles()
        .then((response) => {
          if (response) {
            this.parseUpcomingDraws(response);
          }
        });
    }, (!retryMode) ? this.timerUpcomingRaffles : this.timerLiveRaffle);
  }

  async getUpcomingRaffles() {
    let urlBase = APP_CONFIG.BASE_API;
    if (this.userID) {
      const gameId = this.gameId || APP_CONFIG.GAME_ID;
      const operatorId = APP_CONFIG.OPERATOR_ID;

      urlBase += APP_CONFIG.ENDPOINT_GET_NEXT_MATCHES_CARDS;
      urlBase += `?userId=${this.userID}`;
      urlBase += `&gameCode=${gameId}`;
      urlBase += `&operatorId=${operatorId}`;
    } else {
      urlBase += APP_CONFIG.ENDPOINT_GET_NEXT_MATCHES;
    }

    const headers = new Headers({
      // 'Content-Type': 'application/json',
      'Content-Type': 'text/plain',
    });

    const requestOptions = {
      method: 'GET',
      cache: 'no-cache',
      headers,
      redirect: 'follow',
    };

    const returnedData = await fetch(urlBase, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.nextMatches && result.nextMatches.length) {
          this.dispatchRequestUpcomingRaffles(false);
          return result.nextMatches;
        }

        if (result && result.length) {
          this.dispatchRequestUpcomingRaffles(false);
          return result;
        }

        this.requestUpcomingRafflesError += 1;
        if (this.requestUpcomingRafflesError === 3) {
          this.store.dispatch(constants.UPDATE_DATA_UPCOMINGDRAW, []);
          this.store.dispatch(constants.SHOW_ERROR_MESSAGE, true);
        }

        this.dispatchRequestUpcomingRaffles(true);
        return false;
      })
      .catch((error) => {
        console.log(error);

        this.requestUpcomingRafflesError += 1;
        if (this.requestUpcomingRafflesError === 3) {
          this.store.dispatch(constants.SHOW_ERROR_MESSAGE, true);
        }

        this.dispatchRequestUpcomingRaffles(true);
        return false;
      });

    return returnedData;
  }

  async getLastResults() {
    let urlBase = APP_CONFIG.BASE_API;
    urlBase += APP_CONFIG.ENDPOINT_LAST_MATCHES_RESULTS;
    const headers = new Headers({
      // 'Content-Type': 'application/json',
      'Content-Type': 'text/plain',
    });

    const requestOptions = {
      method: 'GET',
      cache: 'no-cache',
      headers,
      redirect: 'follow',
    };

    const returnedData = await fetch(urlBase, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.length) {
          this.requestLastResultsError = 0;
          this.dispatchRequestLastResults(false);
          return result;
        }

        // Return error
        this.requestLastResultsError += 1;
        if (this.requestLastResultsError === 3) {
          this.store.dispatch(constants.SHOW_ERROR_MESSAGE, true);
        }

        this.dispatchRequestLastResults(true);
        return false;
      })
      .catch((error) => {
        this.requestLastResultsError += 1;
        if (this.requestLastResultsError === 3) {
          this.store.dispatch(constants.SHOW_ERROR_MESSAGE, true);
        }

        console.log(error);
        this.dispatchRequestLastResults(true);

        return false;
      });

    return returnedData;
  }
}
